/************ Custom Font ************/
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Bold.ttf');
	font-weight: 700;
}
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Medium.ttf');
	font-weight: 500;
}
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Book.ttf');
	font-weight: 400;
}

/************ Default Css ************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #eee !important;
  color: #222 !important;
  font-family: 'Centra', sans-serif !important;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p, a, li, button, ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}

@media (min-width:1700px) {
	main .container {
		max-width: 100%;
		padding: 0 150px;
	}
}
/************ Navbar Css ************/
nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  background-color: #efefef;
  transition:  0.32s ease-in-out;
}
nav.navbar.scrolled {
  padding: 0px 0;
  background-color: #efefef;
  border-bottom: 1px solid rgb(0, 0, 0, 0.85);
}
nav.navbar a.navbar-brand {
    max-width: 45px;
    width: 9%;
}
nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 500;
  color: #222 !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.85;
}
nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
    opacity: 1;
}
span.navbar-text {
    display: flex;
    align-items: center;
    justify-content: center;
}
.social-icon {
  display: inline-block;
  margin-left: 14px;
}
.social-icon a {
  width: 42px;
  height: 42px;
  background: rgba(217, 217, 217, 0.1);
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid rgba(255, 255, 255, 0.5);
}
.social-icon a::before {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 50%;
  transform: scale(0);
transition: 0.3s ease-in-out;
}
.social-icon a:hover::before {
  transform: scale(1);
}
.social-icon a img {
width: 40%;
z-index: 1;
transition: 0.3s ease-in-out;
}
.social-icon a:hover img {
filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}
.navbar-text button {
  font-weight: 700;
  color: #121212;
  border: 2px solid #121212;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
	position: relative;
	background-color: transparent;
	transition: 0.3s ease-in-out;
}
.navbar-text button span {
  z-index: 1;
}
.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #121212;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.navbar-text button:hover {
  color: #fff;
}
.navbar-text button:hover::before {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
}
nav.navbar .navbar-toggler{
  border: 0;
}
nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
	outline: none;
    box-shadow: none;
}

nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 3px solid #222;
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
	border-bottom: 2px solid #222;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
	width: 24px;
	position: absolute;
	height: 2px;
	background-color: #222;
	top: 0;
	left: 0;
	content: '';
	z-index: 2;
	transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
	top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
	transform: rotate(45deg);
	background-color: #222;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
	transform: translateY(8px) rotate(-45deg);
	background-color: #222;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
	border-color: transparent;
}
/************ Banner Css ************/
.banner {
  margin-top: 0;
  padding: 200px 0 40px 0;
  /* background-image: url('./assets/img/banner-bg.png'); */
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}
.banner .tagline {
  color: #222;
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 0px;
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
}
.banner h1 {
  font-size: 65px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}
.banner p {
  color: #000;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}
.banner button {
  color: #fff;
  border: 2px solid #111;
  padding: 18px 34px;
  font-size: 18px;
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.5);
  /* background: rgba(126, 50, 150, 0.85); */
  background: linear-gradient(90.21deg, rgba(170, 54, 124, 0.85) -5.91%, rgba(74, 47, 189, 0.85) 111.58%);
}
.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
  color: #fff;
}
.banner button:hover svg {
  margin-left: 25px;
}
.banner img {
  z-index: -999;
  margin-top: -40px;
  width: 400px;
}
.txt-rotate > .wrap {
  border-right: 0.08em solid #666;
}

/************ Skills Css ************/
.skill {
  /* background-image: url('./assets/img/color-sharp.png'); */
  background-size: cover;
  position: relative;
}
.skill-bx {
  text-align: center;
  padding: 40px 40px;
}
.skill h2 {
	font-size: 45px;
	font-weight: 700;
}
.skill-bx .col-12{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}
.skill-bx .col-12:not(:first-child){
  margin-top: 16px;
}
.skill-bx .skill-name{
  margin-left: 16px;
}
.skill h4 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 0;
}
/* .skill button{
  border: 1px white solid
} */
.skill button {
  font-weight: 400;
  color: #222;
  border-right: 1px solid #666;
  padding: 0px 16px;
  font-size: 18px;
	position: relative;
	background-color: transparent;
  cursor: default;
  pointer-events: none;
	transition: 0.3s ease-in-out;
}
.skill .skill-name button:last-child {
  border-right: 0px;
}

/************ Experience Css ************/
.experience {
  /* background-image: url('./assets/img/color-sharp.png'); */
  background-size: cover;
  position: relative;
}
.experience-bx {
  text-align: center;
  padding: 40px 40px;
}
.experience h2 {
	font-size: 45px;
	font-weight: 700;
}
.experience .experience-type{
  text-align: left;
  border: 1px solid #666;
  border-radius: 20px;
  padding: 40px;
  max-width: 1000px;
}
.experience .experience-type:not(:first-child){
  margin-top: 16px;
}
.experience .experience-type ul li:not(:first-child){
  margin-top: 16px;
}
/************ Projects Css ************/
.project {
  padding: 40px 0;
  position: relative;
  background-color: #eee;
}
.project h2 {
	font-size: 45px;
	font-weight: 700;
  text-align: center;
  margin-bottom: 40px;
}
.project .nav.nav-pills {
  width: 72%;
  margin: 0 auto;
  border-radius: 50px;
  background-color: rgb(1 1 1 / 10%);
  overflow: hidden;
  cursor: pointer;
}
.project .nav.nav-pills .nav-item {
  width: 33.33333%;
}
.project .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 0;
  padding: 17px 0;
  color: #121212;
  width: 100%;
  font-size: 17px;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
}
.project .nav.nav-pills .nav-link::before {
    content: "";
    position: absolute;
    width: 0;
    height: 100%;
    background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
    top: 0;
    left: 0;
    z-index: -1;
    transition: 0.3s ease-in-out;
}
.project .nav.nav-pills .nav-link.active::before {
    width: 100% !important;
}
.project .nav.nav-pills .nav-link.active {
    border: 2px solid rgba(1, 1, 1, 1);
    color: #fff;
}
.nav-link#projects-tabs-tab-first {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 55px 0px 0px 55px;
}
.nav-link#projects-tabs-tab-second {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.nav-link#projects-tabs-tab-third {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 0 55px 55px 0;
}
.proj-imgbx {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 24px;
}
.proj-imgbx::before {
  content: "";
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}
.proj-imgbx:hover::before {
  height: 100%;
}
.proj-txtx {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  color: #fff;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
}
.proj-imgbx:hover .proj-txtx {
  top: 50%;
  opacity: 1;
}
.proj-txtx h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}
.proj-txtx span {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
}
.background-image-right {
  top: 20%;
  position: absolute;
  bottom: 0;
  width: 35%;
  right: 0;
  z-index: -4;
}

/************ Projects Css ************/
.contact {
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  padding: 40px 0 40px 0;
}
.contact img {
  width: 92%;
}
.contact h2 {
	font-size: 45px;
	font-weight: 700;
  margin-bottom: 30px;
  color: #fff;
}
.contact form input, .contact form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  color: #fff;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}
.contact form textarea {
  min-height: 250px;
}
.contact form input:focus, .contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}
.contact form input::placeholder, .contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}
.contact form input:focus::placeholder, .contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}
.contact form button {
  font-weight: 700;
  color: #000;
  background-color: #fff;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 0;
  position: relative;
  transition: 0.3s ease-in-out;
}
.contact form button span {
  z-index: 1;
  position: relative;
}

.contact form button:hover {
  color: #fff;
}
.contact form button::before {
  content: "";
  background: #121212;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}
.contact form button:hover::before {
  width: 100%;
}

/************ Footer Css ************/
.footer {
  padding: 40px 0;
  background-image: url('./assets/img/footer-bg.png');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

/* .newsletter-bx {
  background: #FFFFFF;
  border-radius: 55px;
  color: #121212;
  padding: 85px 125px;
  margin-bottom: 80px;
  margin-top: -122px;
}
.newsletter-bx h3 {
  font-weight: 700;
  font-size: 25px;
  letter-spacing: 0.5px;
  line-height: 1.2em;
  margin-top: 0.25rem;
  margin-left: 0.5rem;
}
.newsletter-bx img {
  max-height: 40px;
}
.newsletter-bx .newsletter-col a{
  display: flex;
  flex-wrap: wrap;
}
.newsletter-bx .newsletter-col .custom-ml{
  margin-left: -30px;
} */

.footer img {
  width: 26%;
}
.footer p {
  font-weight: 400;
  font-size: 14px;
  color: #B8B8B8;
  letter-spacing: 0.5px;
  margin-top: 20px;
}
@media (max-width:768px) {
  nav.navbar{
    background-color: #eee !important;
    border-bottom: 2px solid black;
  }
  .banner {
    margin-top: 0;
    margin-left: 5px;
    padding: 120px 0 20px 0;
  }
  .banner h1{
    font-size: 44px !important;
  }
  .banner button{
    margin-top: 40px !important;
  }
  .banner img {
    margin-top: 40px;
    width: 300px;
  }
  .skill-bx .skill-name {
    margin-top: 8px;
    margin-left: 0px;
  }
  .social-icon{
    margin-top: 24px;
  }
  .footer .logo {
    width: 10% !important;
  }
  .project {
    padding: 20px 0;
  }
  .experience-bx {
    text-align: center;
    padding: 20px 20px;
  }
}

@media (max-width:1000px) {
  .newsletter-bx {
    padding: 70px 0px !important;
  }
  .newsletter-bx h3{
    font-size: 20px !important;
    margin-top: 0.5rem !important;
  }
  .newsletter-bx .newsletter-col .custom-ml{
    margin-left: 0px;
  }
}
